import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const IconLeft = styled.div`
  align-self: center;
  margin-right: ${props => props.iconmargin}px;
  display: inline-flex;
`;

const IconRight = styled.div`
  align-self: center;
  margin-left: ${props => props.iconmargin}px;
  display: inline-flex;
`;

const useStyles = ({ overwritestyles: { root } = {} }) => makeStyles({
  root: {
    textTransform: 'none',
    borderRadius: '24px',
    padding: '0.875rem 2rem',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1rem',
    ...root
  }
});

const ButtonWithIcon = props => {
  const {
    children, icon, iconposition, iconmargin
  } = props;
  const classes = useStyles(props)();

  const el = useMemo(
    () => (
      <Flex>
        { iconposition === 'left' ? <IconLeft iconmargin={iconmargin}>{icon}</IconLeft> : null }
        <span>{children}</span>
        { iconposition === 'right' ? <IconRight iconmargin={iconmargin}>{icon}</IconRight> : null }
      </Flex>
    ),
    [icon, iconposition, children]
  );

  return (
    <MuiButton
      {...props}
      classes={{
        root: classes.root,
        disabled: classes.disabled
      }}
    >
      {el}
    </MuiButton>
  );
};

ButtonWithIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  iconposition: PropTypes.string,
  icon: PropTypes.node,
  iconmargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overwritestyles: PropTypes.objectOf(PropTypes.any)
};

ButtonWithIcon.defaultProps = {
  overwritestyles: {},
  iconposition: 'left',
  icon: null,
  iconmargin: 4
};

export default ButtonWithIcon;
