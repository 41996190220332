import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonWithIcon from 'components/MuiComponents/ButtonWithIcon';
import rightBracket from 'assets/stepper-right-bracket.svg';

const Bracket = styled.div`
  display: inline-block;
  margin-left: 0.5rem;

  svg {
    fill: ${props => props.color || '#00b33b'};
  }
`;

const ButtonWithRightBracket = props => {
  const {
    children, onClick, fontcolor, overwritestyles
  } = props;
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    fetch(rightBracket)
      .then(res => res.text())
      .then(setSvg);
  }, []);

  const buttonStyle = useMemo(() => ({
    root: {
      textTransform: 'capitalize',
      fontSize: '1rem',
      padding: '0.25rem 0.5rem',
      alignItems: 'center',
      display: 'flex',
      color: fontcolor,
    },
    ...overwritestyles
  }), []);

  return (
    <ButtonWithIcon
      {...props}
      iconposition="right"
      iconmargin={0}
      overwritestyles={buttonStyle}
      onClick={onClick}
    >
      {children}
      {/* eslint-disable-next-line react/no-danger */}
      <Bracket color={fontcolor} dangerouslySetInnerHTML={{ __html: svg }} />
    </ButtonWithIcon>
  );
};

ButtonWithRightBracket.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  fontcolor: PropTypes.string,
  overwritestyles: PropTypes.objectOf(PropTypes.any)
};

ButtonWithRightBracket.defaultProps = {
  onClick: () => {},
  children: null,
  fontcolor: '#00b33b',
  overwritestyles: {}
};

export default ButtonWithRightBracket;
